import React from 'react';
import { Link } from 'react-router-dom';
// import BoldLogo from '../../assets/bold.svg'
export const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <ul className="footer-links">
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://newcareerbusiness.com/terms-condition/"
                    >
                      Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://newcareerbusiness.com/privacy-policy/"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <Link to="/accessibility">Accessibility</Link>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://newcareerbusiness.com/contact-us/"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bootom border-top">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div>
                  <p className="mb-0">Powered by Resume Builder</p>
                  <p className="mb-0 footer-logo bold text-blue">RB</p>
                </div>
              </div>
              <div className="col-sm-6">
                <p className="copyright-text text-right mb-0">
                  ©{new Date().getUTCFullYear()} Resume Builder Limited. All
                  rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
