import PageHeading from "../../components/PageHeading";
import PageSubHeading from "../../components/PageSubHeading";
import PreviewTips from "../../components/PreviewTips";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addWorkExpr,
  editJobData,
} from "../../redux/features/workExperienceSlice";
import { Modal } from "reactstrap";
const WorkHistoryForm = () => {
  const pathname = window.location.pathname;
  const FinalResume = localStorage.getItem("FinalResume");
  const router = useNavigate();
  useSelector((store) => store.workExprData);
  const [SearchParams] = useSearchParams();
  const jobObjectIndex = SearchParams.get("index");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resume_token = localStorage.getItem("resume_token") || null;
  const resume_meta_value_workexpr = JSON.parse(
    localStorage.getItem("resume_meta_value_workexpr")
  );
  let meta_value =
    JSON.parse(localStorage.getItem("resume_meta_value_workexpr"))?.meta_value[
      jobObjectIndex ? jobObjectIndex : null
    ] || null;
  const [jobtitle, setJobtitle] = useState("");
  const [employer, setEmployer] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [checkAfter, setCheckAfter] = useState(false);
  const [checkBefore, setCheckBefore] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [startPresent, setStartPresent] = useState(false);
  const [endPresent, setEndPresent] = useState(false);
  const [check, setCheck] = useState(false);

  const defaultValues = {
    jobtitle,
    employer,
    pincode,
    city,
    country,
    startDate,
    endDate,
    check,
  };

  localStorage.setItem("work_expr_values", JSON.stringify(defaultValues));

  useEffect(() => {
    if (startDate) {
      setStartPresent(false);
    }
    if (endDate) {
      setEndPresent(false);
    }
  }, [startDate, endDate]);

  //Check if start date is after current date
  function isAfterToday(date) {
    const today = new Date();
    today.setHours(23, 59, 59, 998);
    return date > today;
  }
  const isBefore = (date) => {
    if (startDate) {
      return date < startDate;
    }
  };
  const isAfterEnd = (date) => {
    if (endDate) {
      return date > endDate;
    }
  };
  const handleStartDate = (date) => {
    setStartDate(date);
    const isAfter = isAfterToday(date);
    const isAfterEndDate = isAfterEnd(date);

    if (isAfter) {
      setCheckAfter(true);
    } else {
      setCheckAfter(false);
    }
    if (isAfterEndDate && endDate) {
      setCheckBefore(true);
    } else {
      setCheckBefore(false);
    }
  };
  const handleEndDate = (date) => {
    setEndDate(date);
    const isBeforeStart = isBefore(date);
    if (isBeforeStart) {
      setCheckBefore(true);
    } else {
      setCheckBefore(false);
    }
  };
  useEffect(() => {
    if (!FinalResume && !resume_meta_value_workexpr?.meta_value?.length > 0) {
      localStorage.setItem("en_pth", pathname);
    } else {
      let route = "/work-Summary";
      localStorage.setItem("en_pth", route);
    }
  }, [pathname, FinalResume, resume_meta_value_workexpr?.meta_value?.length]);

  useEffect(() => {
    const getData = () => {
      setJobtitle(meta_value?.jobtitle);
      setEmployer(meta_value?.employer);
      setPincode(meta_value?.pincode);
      setCity(meta_value?.city);
      setCountry(meta_value?.country);
      setCheck(meta_value?.check);
      if (meta_value?.start_date) {
        setStartDate(new Date(meta_value?.start_date));
      }
      if (meta_value?.end_date) {
        setEndDate(new Date(meta_value?.end_date));
      }
    };
    if (meta_value) getData();
    // eslint-disable-next-line
  }, []);

  const saveWorkExpr = () => {
    dispatch(
      addWorkExpr({
        data: {
          jobtitle,
          employer,
          city,
          country,
          pincode,
          start_date: startDate,
          end_date: endDate,
          check,
        },
        resume_token,
      })
    )
      .then(() => {
        localStorage.removeItem("work_expr_values");
        router("/work-summary");
      })
      .catch((e) => {
        return e;
      });
  };
  const editJob = (jobObjectIndex) => {
    dispatch(
      editJobData({
        data: {
          jobtitle,
          employer,
          city,
          country,
          pincode,
          start_date: startDate,
          end_date: endDate,
          check,
        },
        resume_token,
        jobObjectIndex,
      })
    );
  };
  const [modalOpen, setModalOpen] = useState(false);
  const handleCheck = (e) => {
    setCheck(!check);
    setEndDate(null);
  };

  const eduData = useSelector(
    (store) => store.resumeData.degreeData.degreesData
  );
  const skills = useSelector((store) => store.skillsData.Skills);
  const summary = useSelector((store) => store.summaryData.Summary);
  const isStudent = useSelector((store) => store.resumeData.studentCheck);
  const workData = useSelector((store) => store.workExprData.workExpr.jobsData);
  const handlePath = () => {
    if (FinalResume) {
      navigate("/final-resume");
    } else if (!FinalResume && eduData.length === 0) {
      navigate("/resume-education ");
    } else if (
      !FinalResume &&
      workData.length > 0 &&
      eduData.length > 0 &&
      !summary &&
      !skills &&
      isStudent === "No"
    ) {
      navigate("/resume-education");
    } else if (
      !FinalResume &&
      workData.length > 0 &&
      eduData.length > 0 &&
      !skills
    ) {
      navigate("/skill");
    } else if (
      !FinalResume &&
      workData.length > 0 &&
      eduData.length > 0 &&
      !summary &&
      skills &&
      isStudent === "No"
    ) {
      navigate("/resume-education");
    } else if (
      !FinalResume &&
      workData.length > 0 &&
      eduData.length > 0 &&
      skills &&
      !summary
    ) {
      navigate("/skill");
    } else if (
      !FinalResume &&
      workData.length > 0 &&
      eduData.length > 0 &&
      skills &&
      summary
    ) {
      navigate("/skill");
    } else if (!FinalResume && workData.length === 0 && eduData.length > 0) {
      navigate("/skill");
    } else if (!FinalResume && eduData.length === 0 && workData.length > 0) {
      navigate("/resume-education");
    }
  };
  const handleBack = () => {
    localStorage.removeItem("work_expr_values");
    if (FinalResume) {
      navigate("/Work-summary");
    } else if (!FinalResume && workData.length > 0) {
      navigate("/Work-summary");
    } else navigate("/expr");
  };

  return (
    <>
      <section className="choose-template-section resume-contact-section pt-4">
        <div className="container pt-1">
          <div className="row flex-direction">
            <div className="col-md-8">
              <div className="mb-4">
                <PageHeading headinglabel="Tell us about your most recent job" />
                <PageSubHeading sub="We’ll start there and work backward." />
              </div>
            </div>
            <div className="col-md-4 ">
              <PreviewTips exprData={defaultValues} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {/* <WorkHistoryForm /> */}
              <form className="resume-contact-form">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Job Title</label>
                      <input
                        type="text"
                        name="jobtitle"
                        value={jobtitle}
                        onChange={(e) => setJobtitle(e.target.value)}
                        className="form-control"
                        placeholder="e.g. Retail Sales Associate"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Employer</label>
                      <input
                        type="text"
                        name="employer"
                        value={employer}
                        onChange={(e) => setEmployer(e.target.value)}
                        className="form-control"
                        placeholder="e.g. H&M"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>City</label>
                      <input
                        type="text"
                        name="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className="form-control"
                        placeholder="e.g. New Delhi"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>Country</label>
                      <input
                        type="text"
                        name="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        className="form-control"
                        placeholder="e.g. India"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>Pin Code</label>
                      <input
                        type="text"
                        name="pincode"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        className="form-control"
                        placeholder="e.g. 110034"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Start Date</label>
                      <div className="date-picker-box">
                        <span className="calendar-icon">
                          <i className="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                        <DatePicker
                          autoComplete="off"
                          onKeyDown={(e) => {
                            if (e.key === "Backspace") {
                              setCheckAfter(false);
                              setStartDate(null);
                              setCheckBefore(false);
                            } else {
                              e.preventDefault();
                            }
                          }}
                          className="form-control"
                          name="start_date"
                          dateFormat="MM/yyyy"
                          closeOnScroll={(e) => e.target === document}
                          selected={startDate ? new Date(startDate) : ""}
                          onChange={(date) => handleStartDate(date)}
                          placeholderText="Select"
                          showMonthYearPicker
                          scrollableMonthYearDropdown
                        />
                        {checkAfter && (
                          <span style={{ color: "red" }}>
                            Your start date can't be after today's date.
                          </span>
                        )}
                        {startPresent && (
                          <span style={{ color: "red" }}>
                            Your start date can't be empty.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>End Date</label>
                      <div className="date-picker-box">
                        <span className="calendar-icon">
                          <i className="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                        <DatePicker
                          onKeyDown={(e) => {
                            if (e.key === "Backspace") {
                              setCheckBefore(false);
                              setEndDate(null);
                            } else {
                              e.preventDefault();
                            }
                          }}
                          autoComplete="off"
                          className="form-control"
                          name="end_date"
                          dateFormat="MM/yyyy"
                          closeOnScroll={(e) => e.target === document}
                          selected={endDate ? new Date(endDate) : ""}
                          onChange={(date) => handleEndDate(date)}
                          placeholderText="Select"
                          showMonthYearPicker
                          scrollableMonthYearDropdown
                          disabled={check}
                        />
                        {checkBefore && (
                          <span style={{ color: "red" }}>
                            Your end date can't be before the start date.{" "}
                          </span>
                        )}
                        {endPresent && (
                          <span style={{ color: "red" }}>
                            Your end date can't be empty.{" "}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div>
                        <label className="custom-check-container">
                          I currently work here
                          <input
                            type="checkbox"
                            onChange={handleCheck}
                            checked={check}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-sm-6 col-6">
                    <button
                      onClick={handleBack}
                      className="btn site-btn border-btn"
                    >
                      Back
                    </button>
                  </div>
                  <div className="col-sm-6 col-6 text-right">
                    <span
                    // onClick={() => setModalOpen(!modalOpen)}
                    >
                      <button
                        type="button"
                        className="btn site-btn bg-blue text-white"
                        data-toggle="modal"
                        data-target="#moreinfoModal"
                        onClick={() => {
                          if (startDate && !endDate && !check) {
                            setEndPresent(true);
                          } else if (!startDate && (endDate || check)) {
                            setStartPresent(true);
                          } else if (
                            (!startDate && !endDate && !check) ||
                            (!checkAfter &&
                              !checkBefore &&
                              !startPresent &&
                              !endPresent)
                          ) {
                            localStorage.removeItem("work_expr_values");
                            if (
                              defaultValues.jobtitle === "" ||
                              !defaultValues.employer
                            ) {
                              setModalOpen(!modalOpen);
                              return;
                            } else if (jobObjectIndex) {
                              localStorage.removeItem("work_expr_values");
                              editJob(jobObjectIndex);
                              router("/work-summary");
                            } else if (!jobObjectIndex) {
                              localStorage.removeItem("work_expr_values");
                              saveWorkExpr();
                              router("/work-Summary");
                            }
                          }
                        }}

                        // onClick={() => {
                        //   localStorage.removeItem("work_expr_values");
                        //   if (defaultValues.jobtitle === "") {
                        //     setModalOpen(!modalOpen);
                        //     return;
                        //   }
                        //   if (jobObjectIndex || jobObjectIndex === "null") {
                        //     editJob(jobObjectIndex);
                        //     router("/work-Summary");
                        //   } else {
                        //     saveWorkExpr();
                        //     // router("/work-Summary");
                        //   }
                        // }}
                      >
                        {" "}
                        NEXT
                      </button>
                    </span>
                  </div>
                </div>
                <Modal
                  toggle={() => setModalOpen(!modalOpen)}
                  isOpen={modalOpen}
                  className="modal-dialog more-info-modal"
                >
                  <div className="modal-content ">
                    <div className="modal-header">
                      <button
                        className="close"
                        onClick={() => {
                          setModalOpen(!modalOpen);
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <h2 className="modal-title h4 mb-3">
                            More Information Needed
                          </h2>
                          <p className="p mb-0">
                            Looks like you haven't entered any past work
                            experience. We recommend that you at least enter
                            your past <b>Position</b> and <b>Company</b>.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="custom-modal-footer">
                      <div className="row">
                        <div className="col-sm-8 pd-0">
                          <button
                            className="d-block btn site-btn border-btn text-inherit"
                            onClick={() => {
                              setModalOpen(!modalOpen);
                              handlePath();
                              localStorage.setItem("strWork", 0);
                              localStorage.removeItem("work_expr_values");
                            }}
                          >
                            I don't have work experience
                          </button>
                        </div>
                        <div className="col-sm-4  pd-0">
                          <Link
                            to="/Work-summary"
                            className="d-block btn site-btn bg-blue text-white"
                            onClick={() => {
                              localStorage.removeItem("work_expr_values");

                              setModalOpen(!modalOpen);
                            }}
                          >
                            OK
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkHistoryForm;
