import React from 'react';

const ScreenLeft = () => {
  return (
    <>
      <div className="left-box-screen">
        <div className="screen-left">
          <h1 className="heading">
            Just three
            <br /> <span className="text-warn">simple</span> steps
          </h1>
          <ul className="list-steps">
            <li>
              <span className="number">1</span>
              <p>Select a template from our library of professional designs</p>
            </li>
            <li>
              <span className="number">2</span>
              <p>Build your resume with our industry-specific bullet points</p>
            </li>
            <li>
              <span className="number">3</span>
              <p>
                Download your resume, print it out and get it ready to send!
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ScreenLeft;
