import React, { useEffect } from 'react';
import ResumeContactPreview from '../../components/ResumeContactPreview';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const SkillPreview = () => {
  const pathname = window.location.pathname;
  const navigate = useNavigate();
  const FinalResume = localStorage.getItem('FinalResume');
  const isStudent = useSelector((store) => store.resumeData.studentCheck);

  useEffect(() => {
    if (!FinalResume) {
      localStorage.setItem('en_pth', pathname);
    }
  }, [pathname, FinalResume]);
  const handleBack = () => {
    if (FinalResume) {
      navigate('/final-resume');
    } else if (isStudent === 'No') {
      navigate('/edu-summary');
    } else {
      navigate('/Work-summary');
    }
  };

  const handleNext = () => {
    navigate('/add-skill');
  };
  return (
    <>
      <section className="choose-template-section resume-contact-section pt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="resume-content-pd">
                <h1 className="eduction-heading">
                  Next, let’s take care of your &nbsp;
                  <span className="font-weight-normal">skills</span>
                </h1>
                <h2 className="tips-text mb-0">
                  Here’s what you need to know:
                </h2>
                <div className="f-18 mb-0">
                  Employers scan skills for relevant keywords.
                </div>
                <div className="f-18 mb-2">
                  We’ll help you choose the best ones.
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <ResumeContactPreview />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-6 col-6">
              <button onClick={handleBack} className="btn site-btn border-btn">
                Back
              </button>
            </div>
            <div className="col-sm-6 col-6 text-right">
              <button
                onClick={handleNext}
                to="/add-skill"
                className="btn site-btn bg-blue text-white"
              >
                NEXT
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SkillPreview;
