import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../components/PageHeading";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import {
  addCustomSectionOne,
  deleteCustomSectionOne,
  editCustomSectionOne,
  getSingleCustomSectionOne,
} from "../../redux/features/customSectionSlice";
import { Button } from "reactstrap";

const CustomSectionOne = () => {
  const dispatch = useDispatch();
  const [SearchParams] = useSearchParams();
  const [sectionName, setSectionName] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");
  const degreeObjectIndex = SearchParams.get("customSecToken");
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const name = localStorage.getItem("name_stateOne");

  const handleSectionName = () => {
    setSectionName(name);
  };

  const customDetailsOne = useSelector(
    (store) => store.customSectionData.custSectionOne
  );
  const singleSection = useSelector((store) => store.customSectionData.dataOne);

  const setDescriptionData = () => {
    if (customDetailsOne) {
      setSectionDescription(customDetailsOne?.description);
    }
  };
  useEffect(() => {
    setDescriptionData();
    if (customDetailsOne.title) {
      dispatch(getSingleCustomSectionOne(degreeObjectIndex));
    }
    // eslint-disable-next-line
  }, [dispatch, degreeObjectIndex]);

  const handleCkEditor = (event, editor) => {
    const data = editor.getData();
    setSectionDescription(data);
  };

  const handleCustomSection = () => {
    handleClose();
    if (!customDetailsOne?.title && sectionDescription !== "") {
      dispatch(
        addCustomSectionOne({
          data: {
            title: sectionName,
            description: sectionDescription,
          },
        })
      );

      navigate("/final-resume");
    } else if (
      customDetailsOne?.description !== "" &&
      sectionDescription !== ""
    ) {
      dispatch(
        editCustomSectionOne({
          id: localStorage.getItem("customSecOneId"),
          data: {
            title: !sectionName ? singleSection?.title : sectionName,
            description: sectionDescription,
          },
        })
      );

      navigate("/final-resume");
    } else if (sectionDescription === "") {
      if (customDetailsOne.description !== null) {
        dispatch(
          deleteCustomSectionOne(localStorage.getItem("customSecOneId"))
        );
        localStorage.removeItem("name_stateOne");
        localStorage.removeItem("sectionHeadOne");
        localStorage.removeItem("resume_meta_value_custom_sec_One");
        localStorage.removeItem("customSecOneId");

        navigate("/final-resume");
      }
    }
  };

  useEffect(() => {
    const setData = () => {
      if (customDetailsOne?.title?.length > 0 && name === null) {
        setSectionDescription(customDetailsOne?.description);
        setSectionName(customDetailsOne?.title);
      } else {
        handleSectionName(name);
      }
    };
    setData();
    // eslint-disable-next-line
  }, [customDetailsOne, degreeObjectIndex]);
  const FinalResume = localStorage.getItem("FinalResume");

  const handleBack = () => {
    if (FinalResume && customDetailsOne?.description?.length > 0) {
      navigate("/final-resume");
    } else {
      navigate("/add-section");
    }
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"This section can't be empty."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill it out or it will be deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Keep Editing
          </Button>
          <Button onClick={handleCustomSection} autoFocus>
            Discard Section
          </Button>
        </DialogActions>
      </Dialog>
      <section className="choose-template-section pt-4">
        <h1>
          {degreeObjectIndex === null
            ? ""
            : `${
                !sectionName
                  ? singleSection?.title?.toUpperCase()
                  : sectionName?.toUpperCase()
              }`}
        </h1>
        <div className="container">
          <div className="mb-5">
            <PageHeading
              headinglabel={
                degreeObjectIndex !== null
                  ? `Showcase your ${
                      !sectionName ? singleSection?.title : sectionName
                    } to an employer`
                  : sectionName
                  ? `Tell us about your Detail ${
                      !sectionName ? singleSection?.title : sectionName
                    }`
                  : "Tell us about your Detail"
              }
            />
          </div>
          <CKEditor
            config={{
              toolbar: [
                "bold",
                "italic",
                "bulletedList",
                "undo",
                "redo",
                "underline",
              ],
            }}
            editor={ClassicEditor}
            data={sectionDescription ? sectionDescription : ""}
            onChange={handleCkEditor}
            onBlur={(event, editor) => {}}
            onFocus={(event, editor) => {}}
          />
          <div className="row mt-4">
            <div className="col-sm-6 col-4">
              <button onClick={handleBack} className="btn site-btn border-btn">
                Back
              </button>
            </div>
            <div className="col-sm-6 col-8 text-right">
              <button
                type="button"
                onClick={() => {
                  if (sectionDescription) {
                    handleCustomSection();
                  } else {
                    handleClickOpen();
                  }
                }}
                className="btn site-btn bg-blue text-white"
              >
                {localStorage.getItem("FinalResume") === "true"
                  ? "SAVE AND NEXT"
                  : "NEXT: FINALIZE"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CustomSectionOne;
