import React, { useState } from "react";
// import ExpButton from "../../components/ExpButton";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../components/PageHeading";
import PageSubHeading from "../../components/PageSubHeading";
import { useDispatch } from "react-redux";
import {
  selectExperience,
  checkifstudent,
} from "../../redux/features/resumeSlice";
const ExpScreen = () => {
  const dispatch = useDispatch();
  //const resumeState = useSelector((store) => store.resumeData);

  const handleExp = (str) => {
    dispatch(selectExperience(str));

    if (str !== "0-3" || str !== "0") {
      dispatch(checkifstudent("No"));
    }
  };
  const handleStudent = (str) => {
    dispatch(checkifstudent(str));
  };

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/select-country`;

    navigate(path);
  };
  const [show, setShow] = useState(false);
  const onClick = () => setShow(true);
  return (
    <>
      <section className="exp-screen-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="exp-content-box">
                <div className="exp-content-inner">
                  <div style={{ position: "relative" }}>
                    <h1 className="page-title">
                      How long have you been working?
                      <span className="info-text-box">
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                        <span className="info-content-box">
                          Include internships, unpaid jobs, and volunteer work.
                        </span>
                      </span>
                    </h1>
                  </div>
                  <PageSubHeading subheading=" We'll find the best templates for your experience level." />
                  <div className="exp-btn-group">
                    <button
                      className="exp-level-btn"
                      type="button"
                      onClick={(e) => {
                        handleExp("0");
                        onClick();
                      }}
                    >
                      No Experience
                    </button>
                    <button
                      className="exp-level-btn"
                      type="button"
                      onClick={(e) => {
                        handleExp("0-3");
                        onClick();
                      }}
                    >
                      Less than 3 years
                    </button>
                    <button
                      className="exp-level-btn"
                      type="button"
                      onClick={(e) => {
                        handleExp("3-5");
                        setTimeout(() => {
                          routeChange();
                        }, 500);
                      }}
                    >
                      3-5 Years
                    </button>
                    <button
                      className="exp-level-btn"
                      type="button"
                      onClick={(e) => {
                        handleExp("5-10");
                        setTimeout(() => {
                          routeChange();
                        }, 500);
                      }}
                    >
                      5-10 Years
                    </button>
                    <button
                      className="exp-level-btn"
                      type="button"
                      onClick={(e) => {
                        handleExp("10+");
                        setTimeout(() => {
                          routeChange();
                        }, 500);
                      }}
                    >
                      10+ Years
                    </button>
                  </div>
                </div>
              </div>
              {show ? (
                <div className="condition-box text-center">
                  <PageHeading headinglabel="Are you a student?" />
                  <div className="exp-btn-group">
                    <button
                      className="exp-level-btn"
                      onClick={(e) => {
                        handleStudent("Yes");
                        setTimeout(() => {
                          routeChange();
                        }, 500);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="exp-level-btn"
                      onClick={(e) => {
                        handleStudent("No");
                        setTimeout(() => {
                          routeChange();
                        }, 500);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExpScreen;
