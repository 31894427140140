import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PageHeading from '../../components/PageHeading';
import PageSubHeading from '../../components/PageSubHeading';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Button } from 'reactstrap';
import {
  addAdditionalInfo,
  deleteAdditionalInfo,
  editAdditionalInfo,
} from '../../redux/features/additionalInfoSlice';
import { arrExtSec } from '../../redux/features/extraSectionSlice';
const AdditionalInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const FinalResume = localStorage.getItem('FinalResume');
  const pathname = location.pathname;
  const [description, setDescription] = useState('');

  // const inputRef = useRef();
  // const [show, setShow] = useState(false);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const addInfoResData = useSelector(
    (store) => store.additionalInfoData.additionalInfoResData
  );
  const additionalInfo = localStorage.getItem(
    'resume_meta_value_additionalInfo'
  );
  useEffect(() => {
    if (!FinalResume) {
      localStorage.setItem('en_pth', pathname);
    }
  }, [pathname, FinalResume]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const setDescriptionData = () => {
    if (additionalInfo) {
      setDescription(additionalInfo);
    }
  };

  const handleCkEditor = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  useEffect(() => {
    setDescriptionData();

    // eslint-disable-next-line
  }, []);

  const handlePath = () => {
    const nameArr = JSON.parse(localStorage.getItem('ext_name_arr'));
    const filterName = nameArr.filter(
      (ele) => ele !== 'Additional-Information'
    );

    handleClose();
    let route = '';
    const pathArray = JSON.parse(localStorage.getItem('extra_section_array'));
    if (pathname !== pathArray[pathArray.length - 1]) {
      for (let i = 0; i < pathArray.length; i++) {
        if (pathArray[i] === pathname) {
          route = pathArray[i + 1];
        }
      }
    } else {
      route = '/final-resume';
    }
    if (additionalInfo === null && description !== '') {
      dispatch(addAdditionalInfo({ data: description }));
      dispatch(arrExtSec(filterName));
      navigate(route);
    } else if (additionalInfo !== null && description !== '') {
      dispatch(
        editAdditionalInfo({
          data: description,
          id: addInfoResData?.id || localStorage.getItem('additionalInfoId'),
        })
      );
      dispatch(arrExtSec(filterName));
      navigate(route);
    } else if (description === '') {
      if (additionalInfo !== null) {
        dispatch(deleteAdditionalInfo(addInfoResData.id));
        dispatch(arrExtSec(filterName));
        navigate(route);
      } else {
        dispatch(arrExtSec(filterName));
        navigate(route);
      }
    }
  };

  const addInfoData = useSelector(
    (store) => store.additionalInfoData.additionalInfo
  );
  const handleBack = () => {
    let route = '';
    const pathArray = JSON.parse(localStorage.getItem('extra_section_array'));
    if (pathname !== pathArray[0]) {
      for (let i = 0; i < pathArray.length; i++) {
        if (pathArray[i] === pathname) {
          route = pathArray[i - 1];
          navigate(route);
        }
      }
    } else if (FinalResume && addInfoData) {
      navigate('/final-resume');
    } else {
      route = '/add-section';
      navigate(route);
    }
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"This section can't be empty."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill it out or it will be deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            className="btn site-btn border-btn"
          >
            Keep Editing
          </Button>
          <Button
            onClick={handlePath}
            autoFocus
            className="btn site-btn bg-blue text-white"
          >
            Discard Section
          </Button>
        </DialogActions>
      </Dialog>
      <section className="choose-template-section pt-4">
        <div className="container">
          <PageHeading headinglabel="Additional Information" />
          <PageSubHeading subheading="Add anything else you want employers to know." />
          <div className="row">
            <div className="col-sm-12 pt-4 pb-4">
              <CKEditor
                config={{
                  toolbar: [
                    'bold',
                    'italic',
                    'bulletedList',
                    'numberedList',
                    'blockQuote',
                    'heading',
                  ],
                }}
                editor={ClassicEditor}
                data={description}
                onChange={handleCkEditor}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-6 col-4">
              {/* <Link to="/add-section" className="btn site-btn border-btn">
                Back
              </Link> */}
              <button
                type="button"
                onClick={handleBack}
                className="btn site-btn bg-white text-black"
              >
                Back
              </button>
            </div>
            <div className="col-sm-6 col-8 text-right">
              {/* <Link to="/final-resume"> */}
              <button
                type="button"
                onClick={() => {
                  if (description) {
                    handlePath();
                  } else {
                    handleClickOpen();
                  }
                }}
                className="btn site-btn bg-blue text-white"
              >
                {localStorage.getItem('FinalResume') === 'true'
                  ? 'SAVE AND NEXT'
                  : 'NEXT: FINALIZE'}
              </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdditionalInfo;
