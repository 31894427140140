import React, { useEffect } from 'react';
import ResumeContactPreview from '../../components/ResumeContactPreview';
import { Link } from 'react-router-dom';
const SummaryPreview = () => {
  const pathname = window.location.pathname;
  const FinalResume = localStorage.getItem('FinalResume');
  useEffect(() => {
    if (!FinalResume) {
      localStorage.setItem('en_pth', pathname)
    }
  }, [pathname,FinalResume]);
  return (
    <>
      <section className="choose-template-section resume-contact-section pt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="resume-content-pd">
                <h1 className="eduction-heading">
                  Finally, let’s work on your &nbsp;
                  <span className="font-weight-normal">summary</span>
                </h1>
                <h2 className="tips-text mb-0">
                  Here’s what you need to know:
                </h2>
                <div className="f-18 mb-0">
                  Your summary shows employers you’re right for their job.
                </div>
                <div className="f-18 mb-2">
                  We’ll help you write a great one with expert content you can
                  customize.
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <ResumeContactPreview />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-6 col-6">
              <Link to="/add-skill" className="btn site-btn border-btn">
                Back
              </Link>
            </div>
            <div className="col-sm-6 col-6 text-right">
              <Link
                to="/add-summary"
                className="btn site-btn bg-blue text-white"
              >
                NEXT
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SummaryPreview;
