import React, { useEffect, useState } from 'react';
import PageHeading from '../../components/PageHeading';
import PageSubHeading from '../../components/PageSubHeading';
import ResumeContactPreview from '../../components/ResumeContactPreview';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getHeading, addHeading } from '../../redux/features/resumeSlice';
import ProfileImage from '../AllTemplate/ProfileImage';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
const schema = yup.object().shape({
  email: yup.string().email().required().max(35),
  fname: yup
    .string()
    .required('First name is required.')
    .trim('First name cannot include whitespaces.')
    .max(34)
    .strict(true),

  lname: yup
    .string()
    .required('surname must be required')
    .trim('First name cannot include whitespaces.')
    .max(34)
    .strict(true),
});
const ResumeContactStruture = () => {
  const dispatch = useDispatch();
  const pathname = window.location.pathname;
  const FinalResume = localStorage.getItem('FinalResume');
  const tempId = useSelector((store) => store.resumeData.template_id);
  const navigate = useNavigate();
  const isStudent = useSelector((store) => store.resumeData.studentCheck);
  const Expr = useSelector((store) => store.resumeData.experienceLevel);
  let meta_value = useSelector((store) => store.resumeData.heading.data);

  const [fname, setFname] = useState('' || meta_value?.fname);
  const [lname, setLname] = useState('' || meta_value?.lname);
  const [phone, setPhone] = useState('');
  const [pincode, setPincode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('' || meta_value?.email);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const defaultHeadingValues = {
    fname,
    lname,
    phone,
    pincode,
    city,
    country,
    email,
  };
  localStorage.setItem(
    'resume_meta_value_heading',
    JSON.stringify(defaultHeadingValues)
  );
  useEffect(() => {
    if (!FinalResume) {
      localStorage.setItem('en_pth', pathname);
    }
  }, [pathname, FinalResume]);

  useEffect(() => {
    const getData = () => {
      setFname(meta_value?.fname);
      setLname(meta_value?.lname);
      setPhone(meta_value?.phone);
      setPincode(meta_value?.pincode);
      setCity(meta_value?.city);
      setCountry(meta_value?.country);
      setEmail(meta_value?.email);
    };
    if (meta_value) getData();
    // eslint-disable-next-line
  }, []);

  const resume_token = localStorage.getItem('resume_token');

  useEffect(() => {
    if (resume_token) {
      if (meta_value !== null) {
        dispatch(getHeading({ meta_key: 'heading', resume_token }));
      }
    }
  }, [dispatch, meta_value, resume_token]);

  const saveHeading = () => {
    dispatch(addHeading(defaultHeadingValues)).then((res)=>{
      calcPerc();
    }).catch((e) => {
      console.log(e);
    });
  };

  const handleClick = () => {
    saveHeading();
    if (localStorage.getItem('FinalResume') === 'true') {
      navigate('/final-resume');
    } else if (isStudent === 'Yes' && (Expr === '0' || Expr === '0-3')) {
      navigate('/resume-education');
    } else {
      navigate('/expr');
    }
  };

  const handleBack = () => {
    FinalResume ? navigate('/final-resume') : navigate('/choose-template');
  };
  const [validationState, setValidationState] = useState(true);
  const [surnameValidate, setSurnameValidate] = useState(true);

  //Function for calculating the percentage
  const calcPerc = () => {
    let perc = 20;
    if (!city && !country && !pincode) { 
      perc -= 7;
    } else if (!country && !pincode) {
      perc -= 5;
    } else if (!city && !pincode) {
      perc -= 5;
    }else if (!city && !country) {
      perc -= 5;
    }
    else if(! city || !country || !pincode){
      perc-=2;
    }
    localStorage.setItem("strHead",perc);
  };
  return (
    <>
      <section className="choose-template-section resume-contact-section pt-4">
        <div className="container pt-1">
          {/* <form> */}
          <div className="row">
            <div className="col-sm-12 pd-6">
              <div className="mb-4">
                <PageHeading headinglabel="What’s the best way for employers to contact you?" />
                <PageSubHeading subheading="We suggest including an email and phone number." />
              </div>
            </div>
          </div>
          {/* without image */}
          <div className="row">
            <div className="col-lg-8">
              <div className="resume-contact-form profile-img-resume-contact">
                {(function () {
                  if (tempId === '4') {
                    return (
                      <div>
                        <div className="profile-img-box">
                          <ProfileImage />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div style={{ display: 'none' }}>
                        <div className="profile-img-box">
                          <ProfileImage />
                        </div>
                      </div>
                    );
                  }
                })()}
                <div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          name="fname"
                          value={fname}
                          {...register('fname', {
                            onChange: (e) => {
                              if (validationState) {
                                setFname(e.target.value.split(' ')[0]);
                              } else {
                                setFname(e.target.value);
                                setValidationState(true);
                              }
                            },
                          })}
                          onKeyPress={(e) => {
                            setValidationState(false);
                          }}
                          className="form-control"
                          placeholder="e.g. Saanvi"
                        />
                        <small className="text-danger">
                          {errors.fname?.message}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Surname</label>
                        <input
                          type="text"
                          name="lname"
                          value={lname}
                          {...register('lname', {
                            onChange: (e) => {
                              if (surnameValidate) {
                                setLname(e.target.value.split(' ')[0]);
                              } else {
                                setLname(e.target.value);
                                setSurnameValidate(true);
                              }
                            },
                          })}
                          onKeyPress={(e) => {
                            setSurnameValidate(false);
                          }}
                          className="form-control"
                          placeholder="e.g. Patel"
                        />
                        <small className="text-danger">
                          {errors.lname?.message}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>City</label>
                        <input
                          type="text"
                          name="city"
                          value={city}
                          onChange={(e) =>
                            setCity(([e.target.name] = e.target.value))
                          }
                          className="form-control"
                          placeholder="e.g. New Delhi"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label>Country</label>
                        <input
                          type="text"
                          name="country"
                          value={country}
                          onChange={(e) =>
                            setCountry(([e.target.name] = e.target.value))
                          }
                          className="form-control"
                          placeholder="e.g. India"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label>Pin Code</label>
                        <input
                          type="zip"
                          id="zip"
                          name="pincode"
                          value={pincode}
                          onChange={(e) =>
                            setPincode(([e.target.name] = e.target.value))
                          }
                          className="form-control"
                          placeholder="e.g. 110034"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Phone</label>
                        <input
                          type="phone"
                          name="phone"
                          value={phone}
                          onChange={(e) =>
                            setPhone(([e.target.name] = e.target.value))
                          }
                          className="form-control"
                          placeholder="e.g. +91 22 1234 5677"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          name="email"
                          value={email}
                          {...register('email', {
                            onChange: (e) => {
                              setEmail(e.target.value);
                            },
                          })}
                          className="form-control"
                          placeholder="e.g. saanvipatel@sample.in"
                        />
                        <small className="text-danger">
                          {errors.email?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <ResumeContactPreview />
            </div>
          </div>
          {/* with image template */}
          <div className="row mt-4">
            <div className="col-sm-6 col-3">
              <button onClick={handleBack} className="btn site-btn border-btn">
                Back
              </button>
            </div>
            <div className="col-sm-6 col-9 text-right">
              <button
                onClick={handleSubmit(handleClick)}
                className="btn site-btn bg-blue text-white"
              >
                {localStorage.getItem('FinalResume') === 'true'
                  ? 'SAVE AND NEXT'
                  : isStudent === 'Yes' && (Expr === '0' || Expr === '0-3')
                  ? 'NEXT: EDUCATION'
                  : 'NEXT: WORK HISTORY'}{' '}
              </button>
            </div>
          </div>
          {/* </form> */}
        </div>
      </section>
    </>
  );
};

export default ResumeContactStruture;
