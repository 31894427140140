import React, { useEffect } from 'react';
import ResumeContactPreview from '../../components/ResumeContactPreview';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const ResumeEduction = () => {
  let navigate = useNavigate();
  const pathname = window.location.pathname;
  const FinalResume = localStorage.getItem('FinalResume');
  const Edudata = useSelector(
    (store) => store.resumeData.degreeData.degreesData
  );
  const workdata = useSelector((store) => store.workExprData.workExpr.jobsData);
  const isStudent = useSelector((store) => store.resumeData.studentCheck);
  const handleStudentcheck = () => {
    if (Edudata.length > 0) {
      navigate('/edu-summary');
    } else {
      navigate('/edu-del');
    }
  };
  useEffect(() => {
    if (!FinalResume) {
      localStorage.setItem('en_pth', pathname);
    }
  }, [pathname, FinalResume]);
  const handleBack = () => {
    if (FinalResume) {
      navigate('/final-resume');
    }else if (workdata.length === 0 && isStudent === 'No') {
      navigate('/Work-summary');
    } else if (workdata.length > 0 && isStudent === 'Yes') {
      navigate('/resume');
    } else if (workdata.length > 0) {
      navigate('/Work-summary');
    } else {
      navigate('/resume');
    }
  };
  return (
    <>
      <section className="choose-template-section resume-contact-section pt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="resume-content-pd">
                <h1 className="eduction-heading">
                  Great, let’s work on your&nbsp;
                  <span className="font-weight-normal">education</span>
                </h1>
                <h2 className="tips-text">Here’s what you need to know:</h2>
                <ul className="tips-list">
                  <li>Employers quickly scan the education section.</li>
                  <li>
                    We’ll take care of the formatting so it’s easy to find.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 ">
              <ResumeContactPreview />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-6 col-6">
              <button onClick={handleBack} className="btn site-btn border-btn">
                Back
              </button>
            </div>
            <div className="col-sm-6 col-6 text-right">
              <button
                className="btn site-btn bg-blue text-white"
                type="button"
                onClick={handleStudentcheck}
              >
                NEXT
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResumeEduction;
