import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import DescrptionSection from "../../components/DescrptionSection";
import PageHeading from "../../components/PageHeading";
import PageSubHeading from "../../components/PageSubHeading";
import PreviewTips from "../../components/PreviewTips";
import {
  addEducation,
  editIndexData,
  getDegree,
  getEducation,
} from "../../redux/features/resumeSlice";

const EducationDetailForm = ({ handleRemoval }) => {
  const resumeState = useSelector((store) => store.resumeData);
  const pathname = window.location.pathname;
  const FinalResume = localStorage.getItem("FinalResume");
  const [SearchParams] = useSearchParams();
  const degreeObjectIndex = SearchParams.get("index");
  const dispatch = useDispatch();
  const resume_meta_value_education = JSON.parse(
    localStorage.getItem("resume_meta_value_education")
  );
  const Edudata = useSelector(
    (store) => store.resumeData.degreeData.degreesData
  );

  useEffect(() => {
    if (
      !FinalResume &&
      // !resume_meta_value_education?.meta_value &&
      !resume_meta_value_education?.meta_value?.length > 0
    ) {
      localStorage.setItem("en_pth", pathname);
    } else {
      let route = "/edu-summary";
      localStorage.setItem("en_pth", route);
    }
  }, [pathname, FinalResume, resume_meta_value_education?.meta_value?.length]);

  useEffect(() => {
    dispatch(getDegree());
  }, [dispatch]);

  let meta_value =
    JSON.parse(localStorage.getItem("resume_meta_value_education"))?.meta_value[
      degreeObjectIndex ? degreeObjectIndex : null
    ] || null;
  const [sname, setSname] = useState("");
  const [slname, setSLname] = useState("");
  const [sdegree, setSDegree] = useState("");
  const [fieldOStudy, setFieldOStudy] = useState("");
  const [gMonth, setGMonth] = useState("");
  const [gYear, setGYear] = useState("");
  const [sdescription, setSDescription] = useState("");

  const defaultValues = {
    sname,
    slname,
    sdegree,
    fieldOStudy,
    gMonth,
    sdescription,
    gYear,
  };
  localStorage.setItem("edu_summ_values", JSON.stringify(defaultValues));

  useEffect(() => {
    const getData = () => {
      setSname(meta_value?.sname);
      setSLname(meta_value?.slname);
      setSDegree(meta_value?.sdegree);
      setFieldOStudy(meta_value?.fieldOStudy);
      setGMonth(meta_value?.gMonth);
      setGYear(meta_value?.gYear);
      setSDescription(meta_value?.sdescription);
    };
    if (meta_value) getData();
    // eslint-disable-next-line
  }, []);

  const resume_token = localStorage.getItem("resume_token");
  const navigate = useNavigate();
  useEffect(() => {
    if (resume_token) {
      if (meta_value) {
        dispatch(getEducation({ meta_key: "education", resume_token }));
      }
    }
  }, [dispatch, meta_value, resume_token]);

  const saveEducation = (e) => {
    if (sname.length === 0) {
      return toast.error("Please fill the fields");
    }
    dispatch(
      addEducation({
        data: {
          sname,
          slname,
          sdegree,
          fieldOStudy,
          gMonth,
          gYear,
          sdescription,
        },
        resume_token,
      })
    );
    navigate("/edu-summary");
  };
  const editEducation = (degreeObjectIndex) => {
    if (sname.length === 0) {
      return toast.error("Please fill the fields");
    }
    dispatch(
      editIndexData({
        data: {
          sname,
          slname,
          sdegree,
          fieldOStudy,
          gMonth,
          gYear,
          sdescription,
        },
        resume_token,
        degreeObjectIndex,
      })
    );
    navigate("/edu-summary");
  };
  const YEAR = [];
  for (let i = 1957; i <= 2032; i++) {
    YEAR.unshift(i);
  }

  const handleBack = () => {
    localStorage.removeItem("edu_summ_values")
    if (FinalResume || Edudata?.length > 0) {
      navigate("/edu-summary");
    } else {
      navigate("/resume-education");
    }
  };

  return (
    <>
      <section className="pt-4 choose-template-section">
        <div className="container pt-1">
          <div className="row flex-direction">
            <div className="col-md-8 pd-6">
              <PageHeading headinglabel="Tell us about your education" />
              <PageSubHeading subheading="Include every school, even if you're still there or didn't graduate." />
            </div>
            <div className="col-md-4 pd-6">
              <PreviewTips formData={defaultValues} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <form className="resume-contact-form">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>School Name</label>
                      <input
                        type="text"
                        name="sname"
                        value={sname}
                        onChange={(e) => setSname(e.target.value)}
                        className="form-control"
                        placeholder="e.g. Oxford Software Institute & Oxford School of English"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>School Location</label>
                      <input
                        type="text"
                        name="slname"
                        value={slname}
                        onChange={(e) => setSLname(e.target.value)}
                        className="form-control"
                        placeholder="e.g. New Delhi, India"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Degree</label>
                      <div className="slect-box">
                        <span className="select-icon">
                          <i className="fa fa-caret-down"></i>
                        </span>
                        <select
                          className="form-control"
                          name="sdegree"
                          value={sdegree}
                          onChange={(e) => setSDegree(e.target.value)}
                        >
                          <option hidden>Select</option>
                          {resumeState?.degreeList?.map((data) => {
                            return (
                              <option value={data.title} key={data.id}>
                                {data.title}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Field of Study</label>
                      <input
                        type="text"
                        name="fieldOStudy"
                        value={fieldOStudy}
                        onChange={(e) => setFieldOStudy(e.target.value)}
                        className="form-control"
                        placeholder="e.g. Financial Accounting"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <label>Graduation Month</label>
                      <div className="slect-box">
                        <span className="select-icon">
                          <i className="fa fa-caret-down"></i>
                        </span>

                        <select
                          className="form-control"
                          name="gMonth"
                          value={gMonth}
                          onChange={(e) => setGMonth(e.target.value)}
                        >
                          <option hidden>Month</option>
                          <option value="Jan">Jan</option>
                          <option value="Feb">Feb</option>
                          <option value="Mar">Mar</option>
                          <option value="Apr">Apr</option>
                          <option value="May">May</option>
                          <option value="Jun">Jun</option>
                          <option value="Jul">Jul</option>
                          <option value="Aug">Aug</option>
                          <option value="Sep">Sep</option>
                          <option value="Oct">Oct</option>
                          <option value="Nov">Nov</option>
                          <option value="Dec">Dec</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <label>Graduation Year</label>
                      <div className="slect-box">
                        <span className="select-icon">
                          <i className="fa fa-caret-down"></i>
                        </span>
                        <select
                          className="form-control"
                          name="gYear"
                          onChange={(e) => setGYear(e.target.value)}
                          value={gYear}
                        >
                          <option hidden>Year</option>
                          {YEAR?.map((data) => {
                            return (
                              <option key={data} value={data}>
                                {data}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <DescrptionSection
                handleRemoval={handleRemoval}
                setSDescription={setSDescription}
                sDescription={sdescription}
              />
              <div className="row mt-4">
                <div className="col-sm-6 col-6">
                  <button
                    onClick={handleBack}
                    className="btn site-btn border-btn"
                  >
                    Back
                  </button>
                </div>
                <div className="col-sm-6 col-6 text-right">
                  {/* <Link to="/edu-summary"> */}
                  <button
                    type="button"
                    className="btn site-btn bg-blue text-white"
                    onClick={() => {
                      localStorage.removeItem("edu_summ_values");
                      if (degreeObjectIndex || degreeObjectIndex === "null") {
                        editEducation(degreeObjectIndex);
                      } else {
                        saveEducation();
                      }
                    }}
                  >
                    NEXT
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EducationDetailForm;
