import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';


const ResumeSidebar = ({
  setSaveNext,
  setExample,
  pdf,
  resumeName,
  setModalOpen: setOpen,
  modalOpen: open,
  setStateFix,
  statefix
}) => {
  
  const navigate = useNavigate();
// eslint-disable-next-line
  const [includeField,setNotIncludefield]=useState(false)
  const [modalOpen, setModalOpen] = useState(false);
  const loginDatas = useSelector((store) => store.resumeData);
  const userProfile = loginDatas?.loginData?.data?.userInfo;

  const sectionOne = useSelector(
    (store) => store.customSectionData.custSectionOne
  );
  const sectionTwo = useSelector(
    (store) => store.customSectionData.custSectionTwo
  );
  const accompdata = useSelector(
    (store) => store.accomplishmentsData.accomplishment
  );
  const cData = useSelector((store) => store.certificationData.certification);
  const additionalData = useSelector(
    (store) => store.additionalInfoData.additionalInfo
  );
  const affilData = useSelector((store) => store.affiliationsData.affiliation);

  const langData = useSelector(
    (store) => store.LanguageData.Language.nativeLang
  );
  const langProData = useSelector(
    (store) => store.LanguageData.Language.proLanguage
  );
  const webLinkData = useSelector((store) => store.webLinksData.webLinks);

  //-----take option value for pdf download--------------------------

  const [Value, setValue] = useState({
    option: "",
  });
  const handleChange = (e) => {
    setValue({
      ...Value,
      [e.target.name]: e.target.value,
    });
  };
  //-----------for resumeName--------------------
  const [nameR, setName] = useState(resumeName);
  useEffect(() => {
    setName(resumeName);
  }, [resumeName]);

  setExample(nameR);

  //------- Download onClick------

  const main = () => {
    if (!userProfile) {
      setOpen(
        loginDatas?.loginData?.data?.token ? navigate("/payment") : !open
      );
    } else if (Value.option === "1") {
      // console.log("NO  IT");

      // if (pdf.pdfExportComponent.current) {
      //   console.log("HAS IT");
      //   pdf.pdfExportComponent.current.save();
      // }

      // let printContents = document.getElementById("input").innerHTML;
      // console.log("printContent", printContents);
      // let originalContents = document.body.innerHTML;
      // console.log("originalContent", originalContents);
      // document.body.innerHTML = printContents;
      // window.print();
      // document.body.innerHTML = originalContents;
      // setModalOpen(!modalOpen);
      // window.location.reload(true);
      // pdf.PDF.current.save();
//-------------------------------
// If absolute URL from the remote server is provided, configure the CORS
// header on that server.
// var url = 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/examples/learning/helloworld.pdf';
let url=document.getElementById("input");
window.scrollTo(0, 0);
     
            
            // const divToPrint = document.querySelector('#element-to-print');
            html2canvas(url).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 190;
                const pageHeight = 290;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                const doc = new jsPDF('pt', 'mm');
                let position = 0;
                doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                    heightLeft -= pageHeight;
                }
                doc.save('download.pdf');
            });
        
// const doc = new jsPDF()
//   doc.html(url, {
//             callback: function (doc) {
//                  doc.save('sample.pdf');
//               }
//           });
// console.log("url",url);
// // Loaded via <script> tag, create shortcut to access PDF.js exports.
// var pdfjsLib = window['pdfjs-dist/build/pdf'];

// // The workerSrc property shall be specified.
// pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
// // Asynchronous download of PDF
// var loadingTask = pdfjsLib.getDocument(url);
// loadingTask.promise.then(function(pdf) {
//   console.log('PDF loaded');
  
//   // Fetch the first page
//   var pageNumber = 1;
//   pdf.getPage(pageNumber).then(function(page) {
//     console.log('Page loaded');
    
//     var scale = 1.5;
//     var viewport = page.getViewport({scale: scale});

//     // Prepare canvas using PDF page dimensions
//     var canvas = document.getElementById('the-canvas');
//     var context = canvas.getContext('2d');
//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     // Render PDF page into canvas context
//     var renderContext = {
//       canvasContext: context,
//       viewport: viewport
//     };
//     var renderTask = page.render(renderContext);
//     renderTask.promise.then(function () {
//      console.log("renderTask",renderTask)
//     });
//   });
// }, function (reason) {
//   // PDF loading error
//   console.error(reason);
// });



    }
    //  else if (Value.option === "3") {
    //   var preHtml = `<!DOCTYPE html><html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset="utf-8" /><link rel="icon" href="%PUBLIC_URL%/favicon.ico" /><meta name="viewport" content="width=device-width, initial-scale=1" /><meta name="theme-color" content="#000000" /><metaname="description"content="Web site created using create-react-app"/><link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" /><link rel="manifest" href="%PUBLIC_URL%/manifest.json" /><scriptsrc="https://kit.fontawesome.com/a076d05399.js"crossorigin="anonymous"></scriptsrc=><linkrel="stylesheet"href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/><linkrel="stylesheet"type="text/css"charset="UTF-8"href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"/><linkrel="stylesheet"type="text/css"href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"/><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous"><title>Resume Builder</title></head><body>`;
    //   var postHtml = "</body></html>";

    //   var html =
    //     preHtml + document.getElementById("input").innerHTML + postHtml;
    //   console.log(html);

    //   var blob = new Blob(["\ufeff", html], {
    //     type: "application/msword",
    //   });
    //   var url =
    //     "data:application/vnd.ms-word;charset=utf-8," +
    //     encodeURIComponent(html);

    //   var today = new Date();
    //   var dd = String(today.getDate()).padStart(2, "0");
    //   var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    //   var yyyy = today.getFullYear();

    //   today = mm + "/" + dd + "/" + yyyy;
    //   let filename = nameR
    //     ? nameR + ".docx"
    //     : "Secondment-agreement " + today + ".docx";
    //   var downloadLink = document.createElement("a");
    //   document.body.appendChild(downloadLink);
    //   if (navigator.msSaveOrOpenBlob) {
    //     navigator.msSaveOrOpenBlob(blob, filename);
    //   } else {
    //     downloadLink.href = url;
    //     downloadLink.download = filename;
    //     downloadLink.click();
    //   }
    //   document.body.removeChild(downloadLink);
    // }
    else {
      const element = document.createElement("a");
      const file = new Blob([document.getElementById("input").innerText], {
        type: "text/plain;charset=utf-8",
      });
      element.href = URL.createObjectURL(file);
      element.download = `${nameR}`;
      document.body.appendChild(element);
      element.click();
    }
  };

  const handleNavigate = (data) => {
    let newPathArr = [];
    if (data === "ResumeSummary") {
      navigate("/add-summary");
    } else if (data === "ResumeSkill") {
      navigate("/add-skill");
    } else if (data === "ResumeEducation") {
      navigate("/edu-summary");
    } else if (data === "ResumeWorkHistory") {
      navigate("/Work-summary");
    } else if (data === "ResumeLanguage") {
      newPathArr.push("/lngg");
      localStorage.setItem("extra_section_array", JSON.stringify(newPathArr));
      navigate("/lngg");
    } else if (data === "ResumeAccomplishment") {
      newPathArr.push("/accm");
      localStorage.setItem("extra_section_array", JSON.stringify(newPathArr));
      navigate("/accm");
    } else if (data === "ResumeAffiliations") {
      newPathArr.push("/afil");
      localStorage.setItem("extra_section_array", JSON.stringify(newPathArr));
      navigate("/afil");
    } else if (data === "ResumeAdditionalInfo") {
      newPathArr.push("/addi");
      localStorage.setItem("extra_section_array", JSON.stringify(newPathArr));
      navigate("/addi");
    } else if (data === "ResumeWebLinks") {
      newPathArr.push("/alnk");
      localStorage.setItem("extra_section_array", JSON.stringify(newPathArr));
      navigate("/alnk");
    } else if (data === "ResumeCertifications") {
      newPathArr.push("/cert");
      localStorage.setItem("extra_section_array", JSON.stringify(newPathArr));
      navigate("/cert");
    } else if (data === "ResumeSocialinfo") {
      navigate("/resume");
    } else if (data === "ResumeCustomSectionOne") {
      navigate("/cus-one");
    } else if (data === "ResumeCustomSectionTwo") {
      navigate("/cus-two");
    }
  };
 
 
  const func1 = () => {
    setNotIncludefield(true)
    setStateFix(true);
   
  
        let scripttag = document.createElement("script");
    let scriptttag1 = document.createElement("script");
    scriptttag1.setAttribute(
      "src",
      "https://cdn.tiny.cloud/1/mssrjr89ebsdm1y8kyavbky8el8cfbhj100tullfsbvh16az/tinymce/6/tinymce.min.js"
    );
    scriptttag1.setAttribute("referrerpolicy", "origin");
    scriptttag1.setAttribute("id","scriptId1")
scripttag.setAttribute("id","scriptId")
    scripttag.innerHTML = `tinymce.init({
       browser_spellcheck : true,
      selector: '#myeditablediv',
     plugins: 'a11ychecker advcode casechange export formatpainter image editimage linkchecker autolink checklist lists  media mediaembed pageembed permanentpen powerpaste table advtable tableofcontents tinycomments tinymcespellchecker',
       tinycomments_mode: 'embedded',
      tinycomments_author: 'Author name',
      branding: false,
    toolbar:false,
    menubar:false,
     inline:true
     });`;
    // scripttag.innerHTML = `tinymce.init({
      
    //   browser_spellcheck : true,
    //   selector: '#myeditablediv',
    //   plugins: ' tinymcespellchecker',
    //   branding: false,
    //   // toolbar: "spellchecker",
    // toolbar:false,
    // menubar:false,
    //  inline:true
    // });`;
 
document.body.appendChild(scriptttag1);
    document.body.appendChild(scripttag);
    scriptttag1.async = true;
    scripttag.async = true;
  }
 
  return (
    <>
  
      <div className="final-resume-sidebar">
        <div className="sidebar-link">
          <Link to="" onClick={func1} className="check-link">
            <i className=" fas fa-spell-check" />
            Spell Check
          </Link>
          <h1 className="sidebar-title">RESUME SECTIONS</h1>
          <div className="resume-section-link">
            <Link to="/resume" className="resume-link">
              <i className=" fa fa-file-text-o" aria-hidden="true" />
              <span className="resume-icon-text">Heading</span>
            </Link>
            <Link to="/add-summary" className="resume-link">
              <i className=" fa fa-bars" aria-hidden="true" />
              <span className="resume-icon-text">Professional Summary</span>
            </Link>
            <Link to="/add-skill" className="resume-link">
              <i className=" fa fa-list-ol" aria-hidden="true" />
              <span className="resume-icon-text">Skills</span>
            </Link>
            <Link to="/Work-summary" className="resume-link">
              <i className=" fa fa-users" aria-hidden="true" />
              <span className="resume-icon-text">Work History</span>
            </Link>
            <Link to="/edu-summary" className="resume-link">
              <i className=" fa fa-graduation-cap" aria-hidden="true" />
              <span className="resume-icon-text">Education</span>
            </Link>

            {accompdata ? (
              <>
                <div onClick={() => handleNavigate("ResumeAccomplishment")}>
                  <Link to={`/accm`} className="resume-link">
                    <i className=" fa fa-pencil" aria-hidden="true" />
                    <span className="resume-icon-text">Accomplishments</span>
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
            {affilData ? (
              <>
                <div onClick={() => handleNavigate("ResumeAffiliations")}>
                  <Link to={`/afil`} className="resume-link">
                    <i className=" fa fa-pencil" aria-hidden="true" />
                    <span className="resume-icon-text">Affiliations</span>
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
            {cData ? (
              <>
                <div onClick={() => handleNavigate("ResumeCertifications")}>
                  <Link to={`/cert`} className="resume-link">
                    <i className=" fa fa-pencil" aria-hidden="true" />
                    <span className="resume-icon-text">Certifications</span>
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
            {additionalData ? (
              <>
                <div onClick={() => handleNavigate("ResumeAdditionalInfo")}>
                  <Link to={`/addi`} className="resume-link">
                    <i className=" fa fa-pencil" aria-hidden="true" />
                    <span className="resume-icon-text">
                      Additional-Information
                    </span>
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
            {langData[0]?.nativeLang || langProData[0]?.language ? (
              <>
                <div onClick={() => handleNavigate("ResumeLanguage")}>
                  <Link to={`/lngg`} className="resume-link">
                    <i className=" fa fa-pencil" aria-hidden="true" />
                    <span className="resume-icon-text">Languages</span>
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
            {webLinkData?.link1 || webLinkData?.link2 || webLinkData?.link3 ? (
              <>
                <div onClick={() => handleNavigate("ResumeWebLinks")}>
                  <Link to={`/alnk`} className="resume-link">
                    <i className=" fa fa-pencil" aria-hidden="true" />
                    <span className="resume-icon-text">Links</span>
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
            {sectionOne?.title?.length > 0 ? (
              <>
                <div onClick={() => handleNavigate("ResumeCustomSectionOne")}>
                  <Link to={`/cus-one`} className="resume-link">
                    <i className=" fa fa-pencil" aria-hidden="true" />
                    <span className="resume-icon-text">{sectionOne.title}</span>
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
            {sectionTwo?.title?.length > 0 ? (
              <>
                <div onClick={() => handleNavigate("ResumeCustomSectionTwo")}>
                  <Link to={`/cus-two`} className="resume-link">
                    <i className=" fa fa-pencil" aria-hidden="true" />
                    <span className="resume-icon-text">{sectionTwo.title}</span>
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
            <Link to="/add-section" className="list-sepration">
              <i className="mr-2 fa fa-plus" aria-hidden="true" />
              <span className="resume-icon-text">Add a section</span>
            </Link>

            <Link
              to=""
              className="list-sepration"
              onClick={() => {
                setModalOpen(!modalOpen);
                setSaveNext(true);
              }}
            >
              <i className="mr-2 fa fa-download" aria-hidden="true" />
              <span className="resume-icon-text">Download</span>
            </Link>
            <Link to="" className="list-sepration">
              <i className="mr-2 fa fa-file" aria-hidden="true" />
              <span className="resume-icon-text">
                A4 (8.27” x 11.69”){" "}
                <i className="mr-2 fa fa-chevron-down" aria-hidden="true" />
              </span>
            </Link>
          </div>
        </div>
      </div>
      {/* download resume modal */}
      <Modal
        toggle={() => setModalOpen(!modalOpen)}
        isOpen={modalOpen}
        className="modal-dialog download-resume-dialog more-info-modal "
      >
        <div className="pb-2 pb-4">
          <span
            aria-hidden={true}
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
            className="cursor-pointer close-btn"
          >
            <span className="close">&times;</span>
          </span>
        </div>
        <div className="modal-content ">
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="modal-title h4 mb-3">Download resume as</h2>
                <p className="p mb-0">
                  <form className="f-14">
                    <div className="download-choice mb-4">
                      <label className="custom-radio-container">
                        Adobe PDF (.pdf)
                        <input
                          type="radio"
                          name="option"
                          value="1"
                          onChange={handleChange}
                        />
                        <span className="checkmark-radio"></span>
                      </label>
                      {/* <label className="custom-radio-container">
                        MS Word Document (.docx)
                        <input
                          type="radio"
                          name="option"
                          value="2"
                          onChange={handleChange}
                        />
                        <span className="checkmark-radio"></span>
                      </label> */}
                      <label className="custom-radio-container">
                        Plain Text (.txt)
                        <input
                          type="radio"
                          name="option"
                          value="2"
                          onChange={handleChange}
                        />
                        <span className="checkmark-radio"></span>
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="semi-bold ">Resume name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Dahlia_Delacruz_Resume"
                        name="resumeName"
                        value={
                          nameR ? nameR : localStorage.getItem("resumeName")
                        }
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </form>
                  <p className="form-control-tip">
                    Tip: Don't use numbers when naming your file.
                  </p>
                </p>
              </div>
            </div>
          </div>
          <div className="custom-modal-footer">
            <div className="row">
              <div className="col-sm-6 pd-0">
                <Link
                  to=""
                  className="d-block btn site-btn border-btn"
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  Cancel
                </Link>
              </div>
              <div className="col-sm-6 pd-0">
                <span onClick={() => setModalOpen(!modalOpen)}>
                  {/* {!userProfile ? ():( */}
                  <button
                    className="d-block w-100 btn site-btn bg-blue text-white"
                    onClick={main}
                  >
                    Download
                  </button>
                  {/* ) } */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ResumeSidebar;
