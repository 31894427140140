import React from "react";
import ReactHtmlParser from "react-html-parser";
const ResumeSkill = ({preview}) => {
  const pathname = window.location.pathname;
  const skills = localStorage.getItem("skillsInLocal");
  // const skillsFromServer = useSelector((store) => store.skillsData.Skills);
  const styleobj = {
    fontSize: "",
  };

  return (
    <>
      <div className="skill-list resume-skill">
        <h1 className={`${preview===true?'heading-resume':'resume-heading'}`}>Skills</h1>
        <div className={`${preview===true?'paragraph1 skills':'paragraph skills'}`} style={styleobj}>
          {skills ? (
            <>{ReactHtmlParser(skills)}</>
          ) : !skills ||
            skills === "" ||
            pathname === "/choose-template" ||
            skills === "<li>&nbsp;</li>" ? (
            <>
              <ul className="skill-list-content ">
                <li>Store opening and closing</li>
                <li>Sales expertise</li>
                <li>Accurate Money Handling</li>
                <li>Store Merchandising</li>
                <li>Loss prevention</li>
                <li>Product promotions</li>
                <li>Guest Services</li>
                <li>Point of Sale Systems</li>
              </ul>
            </>
          ) : (
            <>
              <>{ReactHtmlParser(skills)}</>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ResumeSkill;
