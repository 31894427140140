import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import {Link} from 'react-router-dom'
import BrandLogo from '../../assets/newcareerbusiness__1__2.png';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Header = () => {
  const location = useLocation();

  const [isActive, setActive] = useState('');
  const [pathname, setPathname] = useState('');
  const [enableHader, setEnableHader] = useState(false);
  let isStudent = localStorage.getItem('isStudent') || null;
  let finalResume = localStorage.getItem('FinalResume') || null;

  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    if (
      pathname === '/' ||
      pathname === '/experience-level' ||
      pathname === '/select-country' ||
      pathname === '/choose-template' ||
      (pathname === '/resume' && finalResume === 'true') ||
      pathname === '/Work-summary' ||
      (pathname === '/work-expr-form' && finalResume === 'true') ||
      pathname === '/edu-summary' ||
      (pathname === '/edu-del' && finalResume === 'true') ||
      (pathname === '/add-skill' && finalResume === 'true') ||
      (pathname === '/add-summary' && finalResume === 'true') ||
      pathname === '/final-resume' 
      ||
      pathname === "/accessibility"
    ) {
      setEnableHader(false);
    } else setEnableHader(true);
  }, [pathname, enableHader, finalResume]);

  useEffect(() => {
    if (pathname === '/resume') {
      setActive('heading');
    }
    if (pathname === '/work-expr-form' || pathname === '/expr') {
      setActive('work-history');
    }
    if (pathname === '/edu-del' || pathname === '/resume-education') {
      setActive('education');
    }
    if (pathname === '/add-skill' || pathname === '/skill') {
      setActive('skill');
    }
    if (pathname === '/add-summary' || pathname === '/summary') {
      setActive('summary');
    }
    if (pathname === '/add-section') {
      setActive('finalize');
    }
    // eslint-disable-next-line
  }, [pathname]);

  // const Expr = useSelector((store) => store.resumeData.experienceLevel);
  const ExprData = useSelector((store) => store.workExprData.workExpr.jobsData);
  const eduData = useSelector(
    (store) => store.resumeData.degreeData.degreesData
  );
  const skillsData = useSelector((store) => store.skillsData.Skills);
  const summaryData = useSelector((store) => store.summaryData.Summary);
  const contactData = useSelector((store) => store.resumeData.heading.data);
  return (
    <div>
      <div className="header border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="step-header pt-2 pb-2">
                <div className="brand-logo mt-1 mb-1">
                  <div>
                    <img src={BrandLogo} alt="" />
                  </div>
                </div>
                {enableHader && isStudent === 'No' ? (
                  <div className="step-numbering mt-1 mb-1">
                    <div className="progress-section">
                      <Link
                        to="/resume"
                        className={` ${
                          isActive === 'heading' ? 'step active-link' : 'step '
                        }`}
                        onClick={() => {
                          setActive('heading');
                        }}
                        style={{
                          pointerEvents: `${!contactData?.email ? 'none' : ''}`,
                        }}
                      >
                        <span className="step-text" id="CNTC">
                          <span className="step-nembering">1</span> Heading
                        </span>
                      </Link>
                      <Link
                        to="/Work-summary"
                        className={` ${
                          isActive === 'work-history'
                            ? 'step active-link'
                            : 'step'
                        }`}
                        onClick={() => {
                          setActive('work-history');
                        }}
                        style={{
                          pointerEvents: `${
                            ExprData?.length > 0 ? '' : 'none'
                          }`,
                        }}
                      >
                        <span className="step-text" id="EXPR">
                          <span className="step-nembering">2</span> work-history
                        </span>
                      </Link>

                      <Link
                        to="/edu-summary"
                        className={` ${
                          isActive === 'education' ? 'step active-link' : 'step'
                        }`}
                        onClick={() => {
                          setActive('education');
                        }}
                        style={{
                          pointerEvents: `${eduData?.length > 0 ? '' : 'none'}`,
                        }}
                      >
                        <span className="step-text" id="EDUC">
                          <span className="step-nembering">3</span> education
                        </span>
                      </Link>

                      <Link
                        to="/add-skill"
                        className={` ${
                          isActive === 'skill' ? 'step active-link' : 'step'
                        }`}
                        onClick={() => {
                          setActive('skill');
                        }}
                        style={{
                          pointerEvents: `${
                            skillsData?.length > 0 ? '' : 'none'
                          }`,
                        }}
                      >
                        <span className="step-text" id="HILT">
                          <span className="step-nembering">4</span> skills
                        </span>
                      </Link>
                      <Link
                        to="/add-summary"
                        className={` ${
                          isActive === 'summary' ? 'step active-link' : 'step'
                        }`}
                        onClick={() => {
                          setActive('summary');
                        }}
                        style={{
                          pointerEvents: `${
                            finalResume === 'true' ? '' : 'none'
                          }`,
                        }}
                      >
                        <span className="step-text" id="SUMM">
                          <span className="step-nembering">5</span> summary
                        </span>
                      </Link>
                      <Link
                        to="/final-resume"
                        className={` ${
                          isActive === 'finalize'
                            ? 'step last-step active-link'
                            : 'step last-step'
                        }`}
                        onClick={() => {
                          setActive('finalize');
                        }}
                        style={{
                          pointerEvents: `${
                            summaryData?.length > 0 ? '' : 'none'
                          }`,
                        }}
                      >
                        <span className="step-text" id="FNLZ">
                          <span className="step-nembering">6</span> finalize
                        </span>
                      </Link>
                    </div>
                  </div>
                ) : enableHader ? (
                  <div className="step-numbering mt-1 mb-1">
                    <div className="progress-section">
                      <Link
                        to="/resume"
                        className={` ${
                          isActive === 'heading' ? 'step active-link' : 'step '
                        }`}
                        onClick={() => {
                          setActive('heading');
                        }}
                        style={{
                          pointerEvents: `${!contactData?.email ? 'none' : ''}`,
                        }}
                      >
                        <span className="step-text" id="CNTC">
                          <span className="step-nembering">1</span> Heading
                        </span>
                      </Link>

                      <Link
                        to="/edu-summary"
                        className={` ${
                          isActive === 'education' ? 'step active-link' : 'step'
                        }`}
                        onClick={() => {
                          setActive('education');
                        }}
                        style={{
                          pointerEvents: `${eduData?.length > 0 ? '' : 'none'}`,
                        }}
                      >
                        <span className="step-text" id="EDUC">
                          <span className="step-nembering">2</span> education
                        </span>
                      </Link>
                      <Link
                        to="/Work-summary"
                        className={` ${
                          isActive === 'work-history'
                            ? 'step active-link'
                            : 'step'
                        }`}
                        onClick={() => {
                          setActive('work-history');
                        }}
                        style={{
                          pointerEvents: `${
                            ExprData?.length > 0 ? '' : 'none'
                          }`,
                        }}
                      >
                        <span className="step-text" id="EXPR">
                          <span className="step-nembering">3</span> work-history
                        </span>
                      </Link>
                      <Link
                        to="/add-skill"
                        className={` ${
                          isActive === 'skill' ? 'step active-link' : 'step'
                        }`}
                        onClick={() => {
                          setActive('skill');
                        }}
                        style={{
                          pointerEvents: `${
                            skillsData?.length > 0 ? '' : 'none'
                          }`,
                        }}
                      >
                        <span className="step-text" id="HILT">
                          <span className="step-nembering">4</span> skills
                        </span>
                      </Link>
                      <Link
                        to="/add-summary"
                        className={` ${
                          isActive === 'summary' ? 'step active-link' : 'step'
                        }`}
                        onClick={() => {
                          setActive('summary');
                        }}
                        style={{
                          pointerEvents: `${
                            summaryData?.length > 0 ? '' : 'none'
                          }`,
                        }}
                      >
                        <span className="step-text" id="SUMM">
                          <span className="step-nembering">5</span> summary
                        </span>
                      </Link>
                      <Link
                        to="/final-resume"
                        className={` ${
                          isActive === 'finalize'
                            ? 'step last-step active-link'
                            : 'step last-step'
                        }`}
                        onClick={() => {
                          setActive('finalize');
                        }}
                        style={{
                          pointerEvents: `${
                            finalResume === 'true' ? '' : 'none'
                          }`,
                        }}
                      >
                        <span className="step-text" id="FNLZ">
                          <span className="step-nembering">6</span> finalize
                        </span>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
