import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
const ResumeWorkHistory = ({ exprData ,preview}) => {
  const styleobj = {
    fontSize: "",
  };
  const [SearchParams] = useSearchParams();
  const degreeObjectIndex = SearchParams.get("index");

  const pathname = window.location.pathname;
  const resumeWorkExprState = useSelector(
    (store) => store.workExprData.workExpr.jobsData
  );
  const localExprValues = JSON.parse(localStorage.getItem("work_expr_values"));

  //Function for converting a month name--------
  const getMonthName = (date) => {
    if (date) {
      return new Date(date).toLocaleString("default", { month: "short" });
    }
  };

  const getYearNum = (date) => {
    if (date) {
      return new Date(date).getFullYear();
    }
  };

  return (
    <>
      <div className="work-history-box resume-workhistory" id="workHistory">
        <h1 className={`${preview===true?'heading-resume':'resume-heading'}`}>Work History</h1>
        <div className={`${preview===true?'paragraph1':'paragraph'}`} style={styleobj}>
          {resumeWorkExprState?.length !== 0 ? (
            <>
              {resumeWorkExprState?.map((experience, index) => {
                return (
                  <>
                    {index === parseInt(degreeObjectIndex) &&
                    pathname !== "/choose-template" ? (
                      <div key={index}>
                        <p className=" mb-0 ">
                          {experience === null ||
                          pathname === "/choose-template"
                            ? "Software developer"
                            : // eslint-disable-next-line
                            (experience?.jobtitle &&
                                // eslint-disable-next-line
                                experience?.jobtitle ===
                                  // eslint-disable-next-line
                                  localExprValues?.jobtitle) ||
                              pathname.includes("edu-del")
                            ? experience?.jobtitle
                            : localExprValues?.jobtitle}
                        </p>
                        <p className=" mb-0">
                          {experience === null ||
                          pathname === "/choose-template"
                            ? "H & M Retail Sales Associate"
                            : // eslint-disable-next-line
                            (experience?.employer &&
                                // eslint-disable-next-line
                                experience?.employer ===
                                  // eslint-disable-next-line
                                  localExprValues?.employer) ||
                              pathname.includes("edu-del")
                            ? experience?.employer
                            : localExprValues?.employer}
                        </p>
                        <p className="mb-0">
                          {experience === null ||
                          pathname === "/choose-template"
                            ? "New Delhi, India"
                            : (experience?.city &&
                                experience?.country &&
                                // eslint-disable-next-line
                                experience?.city === localExprValues?.city &&
                                experience?.country ===
                                  // eslint-disable-next-line
                                  localExprValues?.country) ||
                              pathname.includes("edu-del")
                            ? `${experience?.city}, ${experience?.country}`
                            : `${localExprValues?.city}, ${localExprValues?.country} `}
                        </p>
                        <p className="Date mb-0 ">
                          {experience === null ||
                          pathname === "/choose-template"
                            ? "May 2016 - Current"
                            : !localExprValues?.startDate &&
                              !localExprValues?.endDate &&
                              !localExprValues?.check
                            ? ``
                            : localExprValues?.startDate &&
                              localExprValues?.endDate &&
                              !localExprValues?.check
                            ? `${getMonthName(
                                localExprValues?.startDate
                              )} ${getYearNum(
                                localExprValues?.startDate
                              )} - ${getMonthName(
                                localExprValues?.endDate
                              )} ${getYearNum(localExprValues?.endDate)}`
                            : localExprValues?.startDate &&
                              !localExprValues?.endDate &&
                              localExprValues?.check
                            ? `${getMonthName(
                                localExprValues?.startDate
                              )} ${getYearNum(
                                localExprValues?.startDate
                              )} - Current`
                            : !localExprValues?.startDate &&
                              !localExprValues?.endDate &&
                              localExprValues?.check
                            ? `Current`
                            : localExprValues?.startDate &&
                              !localExprValues?.endDate &&
                              !localExprValues?.check
                            ? `${getMonthName(
                                localExprValues?.startDate
                              )} ${getYearNum(localExprValues?.startDate)}`
                            : !localExprValues?.startDate &&
                              localExprValues?.endDate &&
                              !localExprValues?.check
                            ? `${getMonthName(
                                localExprValues?.endDate
                              )} ${getYearNum(localExprValues?.endDate)}`
                            : ``}
                        </p>
                      </div>
                    ) : (
                      <>
                        <br></br>
                        <div key={index}>
                          <p className=" mb-0">
                            {experience === null ||
                            pathname === "/choose-template"
                              ? "Software Developer"
                              : experience?.jobtitle?.length === 0
                              ? "Software Developer"
                              : experience?.jobtitle}
                          </p>

                          <p className="mb-0">
                            {experience === null ||
                            pathname === "/choose-template"
                              ? "Vkaps"
                              : experience?.employer?.length === 0
                              ? "Vkaps"
                              : experience?.employer}
                          </p>
                          <p className="mb-0 ">
                            {experience === null ||
                            pathname === "/choose-template"
                              ? "New Delhi, India"
                              : experience?.city?.length === 0
                              ? "New Delhi, India"
                              : `${experience?.city}, ${experience?.country}`}
                          </p>
                          <p className="mb-0">
                            {experience === null ||
                            pathname === "/choose-template"
                              ? "May 2016 - Current"
                              : experience?.start_date === null &&
                                experience?.end_date === null &&
                                !experience?.check
                              ? ""
                              : experience?.start_date &&
                                experience?.end_date &&
                                !experience?.check
                              ? `${getMonthName(
                                  experience?.start_date
                                )} ${getYearNum(
                                  experience?.end_date
                                )} - ${getMonthName(
                                  experience?.end_date
                                )} ${getYearNum(experience?.end_date)}`
                              : experience?.start_date &&
                                !experience?.end_date &&
                                experience?.check
                              ? `${getMonthName(
                                  experience?.start_date
                                )} ${getYearNum(
                                  experience?.start_date
                                )} - Current`
                              : ``}
                          </p>

                          <br />
                        </div>
                      </>
                    )}
                  </>
                );
              })}
              {/* New  */}
              {!degreeObjectIndex && (
                <div>
                  <p className=" mb-0">
                    {pathname === "/choose-template"
                      ? ""
                      : localExprValues?.jobtitle?.length === 0
                      ? ""
                      : localExprValues?.jobtitle}
                  </p>

                  <p className="mb-0">
                    {pathname === "/choose-template"
                      ? ""
                      : localExprValues?.employer?.length === 0
                      ? ""
                      : localExprValues?.employer}
                  </p>
                  <p className="mb-0 ">
                    {pathname === "/choose-template"
                      ? ""
                      : !localExprValues?.city
                      ? ""
                      : `${localExprValues?.city}, ${localExprValues?.country}`}
                  </p>
                  <p className="mb-0">
                    {pathname === "/choose-template"
                      ? ""
                      : !localExprValues?.startDate &&
                        !localExprValues?.endDate &&
                        !localExprValues?.check
                      ? ""
                      : localExprValues?.startDate &&
                        !localExprValues?.endDate &&
                        !localExprValues?.check
                      ? `${getMonthName(
                          localExprValues?.startDate
                        )} ${getYearNum(localExprValues?.startDate)}`
                      : !localExprValues?.startDate &&
                        localExprValues?.endDate &&
                        !localExprValues?.check
                      ? `${getMonthName(localExprValues?.endDate)} ${getYearNum(
                          localExprValues?.endDate
                        )}`
                      : localExprValues?.startDate &&
                        localExprValues?.endDate &&
                        !localExprValues?.check
                      ? `${getMonthName(
                          localExprValues?.startDate
                        )} ${getYearNum(
                          localExprValues?.startDate
                        )} - ${getMonthName(
                          localExprValues?.endDate
                        )} ${getYearNum(localExprValues?.endDate)}`
                      : localExprValues?.startDate &&
                        !localExprValues?.endDate &&
                        localExprValues?.check
                      ? `${getMonthName(
                          localExprValues?.startDate
                        )} ${getYearNum(localExprValues?.startDate)} - Current`
                      : !localExprValues?.startDate &&
                        !localExprValues?.endDate &&
                        localExprValues?.check
                      ? "Current"
                      : ""}
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              <p className=" mb-0 ">
                {localExprValues?.jobtitle !== "" &&
                pathname !== "/choose_template"
                  ? localExprValues?.jobtitle
                  : "H & M Retail Sales Associate"}{" "}
              </p>
              <p className="mb-0">
                {localExprValues?.employer !== "" &&
                pathname !== "/choose-template"
                  ? localExprValues?.employer
                  : "XYZ_name"}
              </p>
              <p className="city-name mb-0">
                {localExprValues?.city && pathname !== "/choose_template"
                  ? `${localExprValues?.city}, ${localExprValues?.country}`
                  : "New Delhi, India"}
              </p>
              <p className="Date mb-0">
                {!localExprValues?.startDate &&
                !localExprValues?.endDate &&
                !localExprValues?.check
                  ? ``
                  : !localExprValues?.startDate && localExprValues?.endDate
                  ? `${getMonthName(localExprValues?.endDate)} ${getYearNum(
                      localExprValues?.endDate
                    )}`
                  : localExprValues?.startDate &&
                    !localExprValues?.endDate &&
                    localExprValues?.check
                  ? `${getMonthName(localExprValues?.startDate)} ${getYearNum(
                      localExprValues?.startDate
                    )} - Current`
                  : localExprValues?.startDate &&
                    localExprValues?.endDate &&
                    !localExprValues?.check
                  ? `${getMonthName(localExprValues?.startDate)} ${getYearNum(
                      localExprValues?.startDate
                    )} to ${getMonthName(
                      localExprValues?.endDate
                    )} ${getYearNum(localExprValues?.endDate)}`
                  : !localExprValues?.startDate &&
                    !localExprValues.endDate &&
                    localExprValues?.check
                  ? `Current`
                  : localExprValues?.startDate &&
                    !localExprValues?.endDate &&
                    !localExprValues?.check
                  ? `${getMonthName(localExprValues?.startDate)} ${getYearNum(
                      localExprValues?.startDate
                    )}`
                  : ``}
              </p>

              {localExprValues?.jobtitle && pathname !== "/choose-template" ? (
                ""
              ) : (
                <ul>
                  <li>
                    Effectively upsold products by introducing accessories and
                    other add-ons, adding ₹3000 to average monthly sales.
                  </li>
                  <li>
                    Generated brand awareness and positive product impressions
                    to increase sales 22%.
                  </li>
                  <li>
                    Used consultative sales approach to understand customer
                    needs and recommend relevant offerings.
                  </li>
                </ul>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ResumeWorkHistory;
